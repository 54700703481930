export enum HighLowInterval {
  FIVE_MIN = "FIVE_MIN",
  FIFTEEN_MIN = "FIFTEEN_MIN",
  ONE_HOUR = "ONE_HOUR",
  FOUR_HOUR = "FOUR_HOUR",
  TWELVE_HOUR = "TWELVE_HOUR",
  DAILY = "DAILY",
}

export const convertToUIText = (interval: string): string => {
  switch (interval) {
    case HighLowInterval.DAILY:
      return "1D";
    case HighLowInterval.TWELVE_HOUR:
      return "12H";
    case HighLowInterval.FOUR_HOUR:
      return "4H";
    case HighLowInterval.ONE_HOUR:
      return "1H";
    case HighLowInterval.FIFTEEN_MIN:
      return "15M";
    case HighLowInterval.FIVE_MIN:
      return "5M";
    default:
      return "Unknown";
  }
};
