import React from "react";
import "./widget.scss";

interface CounterProps {
  side: string;
}

export const Counter = (props: CounterProps) => {
  const styleSide: string = props.side === "High" ? "high" : "low";

  return (
    <div className="widget_container">
      <div className="header">Top {props.side} Count</div>
      <div className="rows">
        <div className="row title">
          <div className="column">Symbol</div>
          <div className="column">Price</div>
          <div className="column">Count</div>
        </div>
        {[1, 2, 3, 4, 5].map((v, i) => {
          return (
            <div className={"row " + styleSide} key={i}>
              <div className="column">BTC</div>
              <div className="column">50,000</div>
              <div className="column">238</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
