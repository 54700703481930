import React from "react";
import { TextField } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { UserState } from "../../store/user/userSlice";

export const UserProfile = () => {
  const userState: UserState = useAppSelector((state) => state.user);

  return (
    <div className="user_profile_container">
      <TextField
        margin="dense"
        id="username"
        label="Email"
        type="email"
        fullWidth
        variant="standard"
        value={userState.user?.email}
      />
    </div>
  );
};
