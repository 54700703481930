import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceRecord } from "../../models/PriceRecord";
import { MomentumScore } from "../../models/MomentumScore";
import { HighLowInterval } from "../../models/HighLowInterval";

export interface TickerState {
  highLowInterval: HighLowInterval;
  tickerRecords: { high: Array<PriceRecord>; low: Array<PriceRecord> };
  momentumScore: any;
}

const initialState: TickerState = {
  highLowInterval: HighLowInterval.FOUR_HOUR,
  tickerRecords: { high: [], low: [] },
  momentumScore: {
    [HighLowInterval.FIVE_MIN]: { high: 0, low: 0 },
    [HighLowInterval.FIFTEEN_MIN]: { high: 0, low: 0 },
    [HighLowInterval.ONE_HOUR]: { high: 0, low: 0 },
    [HighLowInterval.FOUR_HOUR]: { high: 0, low: 0 },
    [HighLowInterval.TWELVE_HOUR]: { high: 0, low: 0 },
    [HighLowInterval.DAILY]: { high: 0, low: 0 },
  },
};

export const tickerSlice = createSlice({
  name: "ticker",
  initialState,
  reducers: {
    toggleInterval: (state, action: PayloadAction<HighLowInterval>) => {
      state.highLowInterval = action.payload;
    },
    addRecord: (state, action: PayloadAction<PriceRecord>) => {
      const price: PriceRecord = action.payload;
      // Add record to ticker lists
      let ticker = price.isHigh
        ? [...state.tickerRecords["high"]]
        : [...state.tickerRecords["low"]];
      ticker.unshift(price);
      if (ticker.length > 250) {
        ticker.splice(150, 250);
      }
      if (price.isHigh) {
        state.tickerRecords["high"] = [...ticker];
      } else {
        state.tickerRecords["low"] = [...ticker];
      }
    },
    addMomentum: (
      state,
      action: PayloadAction<Map<HighLowInterval, MomentumScore>>
    ) => {
      state.momentumScore = { ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleInterval, addRecord, addMomentum } = tickerSlice.actions;

export default tickerSlice.reducer;
