import React from "react";
import { useAppSelector } from "../../store/hooks";
import { MomentumScore } from "../../models/MomentumScore";
import { convertToUIText, HighLowInterval } from "../../models/HighLowInterval";
import "./tickerMomentum.scss";

enum Side {
  HIGH = "HIGH",
  LOW = "LOW",
}

export const TickerMomentum = () => {
  return (
    <div className="ticker_momentum_container">
      <MomentumGroup side={Side.LOW} />
      <MomentumGroup side={Side.HIGH} />
    </div>
  );
};

interface MomentumGroupProps {
  side: Side;
}

const MomentumGroup = (props: MomentumGroupProps) => {
  return (
    <div className="momentum_group_container">
      <MomentumBar side={props.side} interval={HighLowInterval.DAILY} />
      <MomentumBar side={props.side} interval={HighLowInterval.TWELVE_HOUR} />
      <MomentumBar side={props.side} interval={HighLowInterval.FOUR_HOUR} />
      <MomentumBar side={props.side} interval={HighLowInterval.ONE_HOUR} />
      <MomentumBar side={props.side} interval={HighLowInterval.FIFTEEN_MIN} />
      <MomentumBar side={props.side} interval={HighLowInterval.FIVE_MIN} />
    </div>
  );
};

interface MomentumBarProps {
  side: Side;
  interval: HighLowInterval;
}

const MomentumBar = (props: MomentumBarProps) => {
  const momentumScore = useAppSelector((state) => state.ticker.momentumScore);
  const isHighTicker: boolean = props.side === Side.HIGH;
  const score: MomentumScore = momentumScore[props.interval];
  const barScore =
    score === undefined ? 0 : isHighTicker ? score.high : score.low;
  const scorePercentage: number = (barScore / 1000) * 100;
  const barStyle = {
    width: `${Math.round(scorePercentage)}%`,
  };

  return (
    <div
      title={(
        convertToUIText(props.interval) +
        " " +
        props.side +
        "s"
      ).toLocaleLowerCase()}
      className="momentum_bar_container"
    >
      <div
        className={"bar" + (isHighTicker ? " high" : " low")}
        style={barStyle}
      />
    </div>
  );
};
