import { takeLatest, select, put } from "redux-saga/effects";
import { AuthToken } from "../../models/auth/AuthToken";
import { User } from "../../models/User";
import { sendAlert } from "../snackbar/snackbarSlice";
import { setUser } from "./userSlice";
import { RootState } from "../store";
import * as UserAPI from "../../api/user/user";

export const UserActions = {
  GET_USER: "GET_USER",
};

interface GetUserAction {
  type: typeof UserActions.GET_USER;
}

export const getUser = (): GetUserAction => ({
  type: UserActions.GET_USER,
});

function* getUserSaga() {
  try {
    const authToken: AuthToken = yield select(
      (state: RootState) => state.auth.authToken
    );
    const user: User = yield UserAPI.getUser(authToken);
    yield put(setUser(user));
  } catch (error) {
    yield put(
      sendAlert({
        severity: "error",
        message: error.message,
      })
    );
  }
}

export function* watchGetUser() {
  yield takeLatest(UserActions.GET_USER, getUserSaga);
}
