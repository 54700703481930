import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDialogueTab } from "../../store/auth/authSlice";
import { RootState } from "../../store/store";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { logoutUser } from "../../store/auth/authSaga";
import { selectTab } from "../../store/panel/panelSlice";
import { selectTab as openDialogue } from "../../store/donate/donateSlice";
import {
  PanelTab,
  setSelectedPanel,
  UserState,
} from "../../store/user/userSlice";
import navbarLogo from "./navbar_banner.png";
import "./navbar.scss";

export const Navbar = () => {
  const dispatch = useAppDispatch();
  const userState: UserState = useAppSelector((state: RootState) => state.user);

  return (
    <div className="navbar_container">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="navbar">
          <Toolbar className="navbar_toolbar" variant="dense">
            <IconButton className="navbar_image">
              <img src={navbarLogo} alt={"banner"} />
            </IconButton>
            <Typography
              className="navbar_banner"
              variant="h6"
              sx={{ flexGrow: 1 }}
            >
              <Button onClick={() => dispatch(selectTab("HOME"))}>Home</Button>
              <Button onClick={() => dispatch(selectTab("CHARTS"))}>
                Charts
              </Button>
              <Button onClick={() => dispatch(openDialogue("DONATE"))}>
                Donate
              </Button>
            </Typography>
            <Typography>
              {userState.user != null ? <UserProfile /> : <LoginRegister />}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

const LoginRegister = () => {
  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <Button onClick={() => dispatch(setDialogueTab("LOG_IN"))}>Login</Button>
      <Button onClick={() => dispatch(setDialogueTab("REGISTER"))}>
        Register
      </Button>
    </React.Fragment>
  );
};

const UserProfile = () => {
  const userState: UserState = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  const openPanel = (panel: PanelTab) => {
    dispatch(setSelectedPanel(panel));
    setOpen(false);
  };

  const logout = () => {
    dispatch(logoutUser());
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        className="user_profile"
        onClick={(e) => {
          setOpen(true);
          setAnchor(e.currentTarget);
        }}
      >
        <span>{userState.user?.email}</span>
        <AccountCircleIcon />
      </Button>
      <Menu
        id="basic-menu"
        className="user_profile_menu"
        anchorEl={anchor}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => openPanel("PROFILE")}>Profile</MenuItem>
        <MenuItem onClick={() => openPanel("SUBSCRIPTION")}>
          Subscription
        </MenuItem>
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
