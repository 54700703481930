import { User } from "../../models/User";
import { AuthToken } from "../../models/auth/AuthToken";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { userRoutes } from "../http";

export const getUser = async (authToken: AuthToken): Promise<User> => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${authToken.accessToken}` },
    };
    const response: AxiosResponse<User> = await axios.get(
      userRoutes.user,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
