import React from "react";
import "./tickerBanner.scss";

export const TickerBanner = () => {
  return (
    <div className="ticker_banner_container">
      <div className="side">New Lows</div>
      <div className="side">New Highs</div>
    </div>
  );
};
