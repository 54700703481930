import React from "react";
import "./homePanel.scss";

export const HomePanel = () => {
  return (
    <div className="home_panel_container">
      <h1>👋 Welcome to app, enjoy your stay</h1>
      <h2>Viewing Charts</h2>
      <p>To view charts click the "Chart" tab in the tabs bar above.</p>
    </div>
  );
};
