import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PanelState {
  selectedTab: PanelTab;
}

export type PanelTab = "HOME" | "CHARTS";

const initialState: PanelState = {
  selectedTab: "CHARTS",
};

export const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    selectTab: (state, action: PayloadAction<PanelTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { selectTab } = panelSlice.actions;

export default panelSlice.reducer;
