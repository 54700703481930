import React from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { setSelectedPanel, UserState } from "../../store/user/userSlice";
import CloseIcon from "@mui/icons-material/Close";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { UserProfile } from "./profile";
import { UserSubscription } from "./subscription";
import { Divider } from "@mui/material";
import "./userPanel.scss";

export const UserPanel = () => {
  const dispatch = useAppDispatch();
  const userState: UserState = useAppSelector((state: RootState) => state.user);

  if (userState.selectedPanelTab !== "CLOSED") {
    return (
      <React.Fragment>
        <Dialog
          className="user_panel"
          fullWidth={true}
          maxWidth="md"
          open={true}
          onClose={() => dispatch(setSelectedPanel("CLOSED"))}
        >
          <AppBar className="appbar" sx={{ position: "relative" }}>
            <Toolbar variant="dense">
              <IconButton
                color="secondary"
                edge="start"
                onClick={() => dispatch(setSelectedPanel("CLOSED"))}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                color="secondary"
              >
                User Settings
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={userState.selectedPanelTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(event, newValue) => {
                    dispatch(setSelectedPanel(newValue));
                  }}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Profile" value="PROFILE" />
                  <Tab label="Subscriptions" value="SUBSCRIPTION" />
                </TabList>
              </Box>
              <Divider light />
              <TabPanel value="PROFILE">
                <UserProfile />
              </TabPanel>
              <TabPanel value="SUBSCRIPTION">
                <UserSubscription />
              </TabPanel>
            </TabContext>
          </Box>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return null;
  }
};
