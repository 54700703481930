import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../models/User";

export interface UserState {
  user: User | null;
  selectedPanelTab: PanelTab;
}

export type PanelTab = "PROFILE" | "SUBSCRIPTION" | "CLOSED";

const initialState: UserState = {
  user: null,
  selectedPanelTab: "CLOSED",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setSelectedPanel: (state, action: PayloadAction<PanelTab>) => {
      state.selectedPanelTab = action.payload;
    },
  },
});

export const { setUser, setSelectedPanel } = userSlice.actions;

export default userSlice.reducer;
