import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  TextField,
} from "@mui/material";
import { setDialogueTab, setEmail } from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import * as EmailValidator from "email-validator";
import { AuthCaptcha } from "./authCaptcha";
import { forgotPassword } from "../../store/auth/authSaga";

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [isCaptchaDone, setCaptchaDone] = useState(false);

  const isFormValid = (): boolean => {
    const validEmail = EmailValidator.validate(authState.email);
    return validEmail && isCaptchaDone;
  };

  return (
    <React.Fragment>
      <DialogTitle className="auth_title">HighLowTicker</DialogTitle>
      <DialogContent>
        <DialogContentText className="auth_content">
          <Tab label={"Forgot Password"} className="tab_selected" />
        </DialogContentText>
        <p
          className="existing_code"
          onClick={() => dispatch(setDialogueTab("RESET_PASSWORD"))}
        >
          Already have a code?
        </p>
        <form autoComplete="off">
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={authState.email}
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
          />
        </form>
        <AuthCaptcha open={!isCaptchaDone} setCaptcha={setCaptchaDone} />
      </DialogContent>
      <DialogActions className="auth_actions">
        <Button onClick={() => dispatch(setDialogueTab("LOG_IN"))}>BACK</Button>
        <Button
          disabled={!isFormValid() || authState.isLoading}
          onClick={() => dispatch(forgotPassword(authState.email))}
        >
          SEND RESET EMAIL
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
