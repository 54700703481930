import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { LoginDetails } from "../../models/auth/LoginDetails";
import { AuthToken } from "../../models/auth/AuthToken";
import {
  setAuthToken,
  setCaptcha,
  setDialogueTab,
  setLoading,
} from "./authSlice";
import { sendAlert } from "../snackbar/snackbarSlice";
import { ResetPasswordDetails } from "../../models/auth/ResetPasswordDetails";
import { RegisterDetails } from "../../models/auth/RegisterDetails";
import { User } from "../../models/User";
import { getUser } from "../user/userSaga";
import { setUser } from "../user/userSlice";
import * as AuthAPI from "../../api/auth/auth";

export const AuthActions = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  REGISTER_USER: "REGISTER_USER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
};

// Login User ==============================================================

interface LoginUserAction {
  type: typeof AuthActions.LOGIN_USER;
  payload: LoginDetails;
}

export const loginUser = (details: LoginDetails): LoginUserAction => ({
  type: AuthActions.LOGIN_USER,
  payload: details,
});

function* loginUserSaga(action: PayloadAction<LoginDetails>) {
  try {
    yield put(setLoading(true));
    const token: AuthToken = yield AuthAPI.loginUser(action.payload);
    yield put(
      sendAlert({
        severity: "success",
        message: "Successfully logged in",
      })
    );
    yield put(setCaptcha(false));
    yield put(setDialogueTab("CLOSED"));
    yield put(setAuthToken(token));
    yield put(getUser());
  } catch (error) {
    // if user types incorrect password, require captcha on next login attempt
    yield put(setCaptcha(true));
    yield put(
      sendAlert({
        severity: "error",
        message: error.message,
      })
    );
  } finally {
    yield put(setLoading(false));
  }
}

export function* watchLoginUser() {
  yield takeLatest(AuthActions.LOGIN_USER, loginUserSaga);
}

// Logout User ===============================================================

interface LogoutUserAction {
  type: typeof AuthActions.LOGOUT_USER;
}

export const logoutUser = (): LogoutUserAction => ({
  type: AuthActions.LOGOUT_USER,
});

function* logoutUserAction() {
  try {
    yield put(setUser(null));
    yield put(setAuthToken(null));
    yield put(
      sendAlert({
        severity: "success",
        message: "Successfully logged out User",
      })
    );
  } catch (error) {
    yield put(
      sendAlert({
        severity: "error",
        message: error.message,
      })
    );
  }
}

export function* watchLogoutUser() {
  yield takeLatest(AuthActions.LOGOUT_USER, logoutUserAction);
}

// Register User =============================================================

interface RegisterUserAction {
  type: typeof AuthActions.REGISTER_USER;
  payload: RegisterDetails;
}

export const registerUser = (details: RegisterDetails): RegisterUserAction => ({
  type: AuthActions.REGISTER_USER,
  payload: details,
});

function* registerUserSaga(action: PayloadAction<RegisterDetails>) {
  try {
    yield put(setLoading(true));
    const user: User = yield AuthAPI.registerUser(action.payload);
    yield put(
      sendAlert({
        severity: "success",
        message: "Successfully registered account, you may now log in",
      })
    );
    yield put(setDialogueTab("LOG_IN"));
  } catch (error) {
    yield put(
      sendAlert({
        severity: "error",
        message: error.message,
      })
    );
  } finally {
    yield put(setLoading(false));
  }
}

export function* watchRegisterUser() {
  yield takeLatest(AuthActions.REGISTER_USER, registerUserSaga);
}

// Forgot Password ======================================================

interface ForgotPasswordAction {
  type: typeof AuthActions.FORGOT_PASSWORD;
  payload: string;
}

export const forgotPassword = (email: string): ForgotPasswordAction => ({
  type: AuthActions.FORGOT_PASSWORD,
  payload: email,
});

function* forgotPasswordSaga(action: PayloadAction<string>) {
  try {
    yield put(setLoading(true));
    const message: string = yield AuthAPI.forgotPassword(action.payload);
    yield put(
      sendAlert({
        severity: "success",
        message: message,
      })
    );
    yield put(setDialogueTab("RESET_PASSWORD"));
  } catch (error) {
    yield put(
      sendAlert({
        severity: "error",
        message: error.message,
      })
    );
  } finally {
    yield put(setLoading(false));
  }
}

export function* watchForgotPassword() {
  yield takeLatest(AuthActions.FORGOT_PASSWORD, forgotPasswordSaga);
}

interface ResetPasswordAction {
  type: typeof AuthActions.RESET_PASSWORD;
  payload: ResetPasswordDetails;
}

// Reset Password ========================================================

export const resetPassword = (
  details: ResetPasswordDetails
): ResetPasswordAction => ({
  type: AuthActions.RESET_PASSWORD,
  payload: details,
});

function* resetPasswordSaga(action: PayloadAction<ResetPasswordDetails>) {
  try {
    yield put(setLoading(true));
    const message: string = yield AuthAPI.resetPassword(action.payload);
    yield put(
      sendAlert({
        severity: "success",
        message: message,
      })
    );
    yield put(setDialogueTab("LOG_IN"));
  } catch (error) {
    yield put(
      sendAlert({
        severity: "error",
        message: error.message,
      })
    );
  } finally {
    yield put(setLoading(false));
  }
}

export function* watchResetPassword() {
  yield takeLatest(AuthActions.RESET_PASSWORD, resetPasswordSaga);
}
