import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  TextField,
} from "@mui/material";
import {
  setConfirmPassword,
  setDialogueTab,
  setEmail,
  setPassword,
} from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AuthCaptcha } from "./authCaptcha";
import { registerUser } from "../../store/auth/authSaga";
import * as EmailValidator from "email-validator";

export const Register = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [isCaptchaDone, setCaptchaDone] = useState(false);

  const isFormValid = (): boolean => {
    const validEmail = EmailValidator.validate(authState.email);
    // valid password must have at least 8 characters
    const validPassword = authState.password.length >= 8;
    const passwordsMatch = authState.password === authState.confirmPassword;
    return validEmail && validPassword && passwordsMatch && isCaptchaDone;
  };

  return (
    <React.Fragment>
      <DialogTitle className="auth_title">HighLowTicker</DialogTitle>
      <DialogContent>
        <DialogContentText className="auth_content">
          <Tab
            label={"Log In"}
            onClick={() => dispatch(setDialogueTab("LOG_IN"))}
          />
          <Tab label={"Register"} className="tab_selected" />
        </DialogContentText>
        <form autoComplete="off">
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={authState.email}
            autoComplete="on"
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={authState.password}
            autoComplete="on"
            onChange={(e) => {
              dispatch(setPassword(e.target.value));
            }}
          />
          <TextField
            margin="dense"
            id="confirm_password"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            value={authState.confirmPassword}
            autoComplete="off"
            onChange={(e) => {
              dispatch(setConfirmPassword(e.target.value));
            }}
          />
        </form>
        <AuthCaptcha open={!isCaptchaDone} setCaptcha={setCaptchaDone} />
      </DialogContent>
      <DialogActions className="auth_actions">
        <div />
        <Button
          disabled={!isFormValid() || authState.isLoading}
          onClick={() =>
            dispatch(
              registerUser({
                email: authState.email,
                password: authState.password,
              })
            )
          }
        >
          REGISTER
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
