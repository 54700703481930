import React, { useEffect, useState } from "react";
import { PriceRecord } from "../../models/PriceRecord";
import { TickerHighLow } from "./tickerSide";
import { TickerStatus } from "./tickerStatus";
import { TickerMomentum } from "./tickerMomentum";
import { TickerBanner } from "./tickerBanner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addMomentum, addRecord } from "../../store/ticker/slice";
import { TickerIntervalSelect } from "./tickerIntervalSelect";
import { Client } from "@stomp/stompjs";
import { HighLowInterval } from "../../models/HighLowInterval";
import { MomentumScore } from "../../models/MomentumScore";
import SockJS from "sockjs-client";
import "./ticker.scss";

export const Ticker = () => {
  const dispatch = useAppDispatch();
  const tickerState = useAppSelector((state) => state.ticker);
  const [connected, setConnected] = useState(false);
  const [stompClient, setStompClient] = useState<Client>();
  const socketURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4545/websocket"
      : "websocket";

  const connect = () => {
    const stompConfig = {
      connectionHeaders: {},
      webSocketFactory: () => new SockJS(socketURL),
      reconnectDelay: 500,
      onConnect: () => {
        const highLowTopic = `/highlow/${tickerState.highLowInterval}`;
        stompClient.subscribe(highLowTopic, onHighLowMessage);
        const momentumTopic = "/highlow/momentum";
        stompClient.subscribe(momentumTopic, onMomentumMessage);
        setConnected(true);
      },
    };
    const stompClient = new Client(stompConfig);
    stompClient.activate();
    setStompClient(stompClient);
  };

  const onHighLowMessage = (data: any) => {
    const record: PriceRecord = JSON.parse(data.body);
    dispatch(addRecord(record));
  };

  const onMomentumMessage = (data: any) => {
    const momentumScore: Map<HighLowInterval, MomentumScore> = JSON.parse(
      data.body
    );
    dispatch(addMomentum(momentumScore));
  };

  const disconnect = async (): Promise<void> => {
    if (stompClient != null) {
      stompClient.deactivate().then(() => {
        setConnected(false);
        return;
      });
    }
  };

  useEffect(() => {
    if (connected) {
      disconnect().then(() => {
        connect();
      });
    } else {
      connect();
    }
  }, [tickerState.highLowInterval]);

  return (
    <div className="ticker_container">
      <TickerBanner />
      <TickerIntervalSelect />
      <TickerMomentum />
      <TickerHighLow />
      <TickerStatus connected={connected} />
    </div>
  );
};
