import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChartState {
  isDrawingsToggled: boolean;
  isHeaderToggled: boolean;
  chartInterval: ChartInterval;
}

export type ChartInterval = 1 | 5 | 15 | 60 | 240 | "D";

const initialState: ChartState = {
  isDrawingsToggled: false,
  isHeaderToggled: true,
  chartInterval: 1,
};

export const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    toggleDrawings: (state) => {
      state.isDrawingsToggled = !state.isDrawingsToggled;
    },
    toggleHeader: (state) => {
      state.isHeaderToggled = !state.isHeaderToggled;
    },
    setInterval: (state, action: PayloadAction<ChartInterval>) => {
      state.chartInterval = action.payload;
    },
  },
});

export const { toggleDrawings, toggleHeader, setInterval } = chartSlice.actions;

export default chartSlice.reducer;
