import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SnackbarState {
  isOpen: boolean;
  alert: Alert | null;
}

export interface Alert {
  severity: "error" | "warning" | "info" | "success";
  message: string;
}

const initialState: SnackbarState = {
  isOpen: false,
  alert: null,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    sendAlert: (state, action: PayloadAction<Alert>) => {
      state.isOpen = true;
      state.alert = action.payload;
    },
    closeSnackbar: (state) => {
      state.isOpen = false;
      state.alert = null;
    },
  },
});

export const { sendAlert, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
