import React from "react";

export const Volume = () => {
  return (
    <div className="widget_container">
      <div className="header">Most 24H Volume</div>
      <div className="rows">
        <div className="row title">
          <div className="column">Symbol</div>
          <div className="column">Price</div>
          <div className="column">Volume</div>
        </div>
        {[1, 2, 3, 4, 5].map((v, i) => {
          return (
            <div className="row" key={i}>
              <div className="column">BTC</div>
              <div className="column">50,000</div>
              <div className="column">2,380,000</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
