import React from "react";
import { Dialog } from "@mui/material";
import { Login } from "./login";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDialogueTab } from "../../store/auth/authSlice";
import { Register } from "./register";
import { ForgotPassword } from "./forgotPassword";
import { ResetPassword } from "./resetPassword";
import "./auth.scss";

export const Auth = () => {
  const dialogueTab = useAppSelector((state) => state.auth.dialogueTab);
  const dispatch = useAppDispatch();
  return (
    <Dialog
      className="auth_container"
      open={dialogueTab !== "CLOSED"}
      onClose={() => dispatch(setDialogueTab("CLOSED"))}
      fullWidth
      maxWidth={"xs"}
    >
      {dialogueTab === "LOG_IN" ? (
        <Login />
      ) : dialogueTab === "REGISTER" ? (
        <Register />
      ) : dialogueTab === "FORGOT_PASSWORD" ? (
        <ForgotPassword />
      ) : dialogueTab === "RESET_PASSWORD" ? (
        <ResetPassword />
      ) : null}
    </Dialog>
  );
};
