import React from "react";
import { Ticker } from "./ticker/ticker";
import { Sidebar } from "./sidebar/sidebar";
import { Panel } from "./panel/panel";
import { Navbar } from "./navbar/navbar";
import { Auth } from "./auth/auth";
import { BottomSnackbar } from "./snackbar/bottomSnackbar";
import { UserPanel } from "./user/userPanel";
import { DonateDialogue } from "./donate/donateDialogue";
import "./App.scss";

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <UserPanel />
      <DonateDialogue />
      <Auth />
      <div className="app_container">
        <Sidebar />
        <Panel />
        <Ticker />
      </div>
      <BottomSnackbar />
    </React.Fragment>
  );
}

export default App;
