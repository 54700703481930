import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface AuthCaptchaProps {
  open: boolean;
  setCaptcha: any;
}

export const AuthCaptcha = (props: AuthCaptchaProps) => {
  if (props.open) {
    return (
      <div className="auth_captcha">
        <ReCAPTCHA
          sitekey="6LcdONYcAAAAADHZdx6fenxX6RJSKxMNM0XJleXL"
          onChange={() => props.setCaptcha(true)}
        />
      </div>
    );
  } else {
    return null;
  }
};
