import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { FormControl, IconButton, Input, InputAdornment } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { sendAlert } from "../../store/snackbar/snackbarSlice";
import { Button } from "@mui/material";

interface CryptoAddressProps {
  icon: any;
  alt: string;
  type: "BTC" | "ETH" | "USDT";
  address: string;
}

export const CryptoAddress = (props: CryptoAddressProps) => {
  const dispatch = useAppDispatch();

  return (
    <CopyToClipboard
      text={props.address}
      onCopy={() =>
        dispatch(
          sendAlert({
            severity: "success",
            message: `Successfully copied ${props.type} address to clipboard.`,
          })
        )
      }
    >
      <Button>
        <img src={props.icon} alt={props.alt} />
        <div>{props.type.concat(" Address")}</div>
        <FormControl sx={{ m: 1, width: "25ch" }}>
          <Input
            id="filled-adornment-password"
            type="text"
            value={props.address}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="copy crypto address" edge="end">
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Button>
    </CopyToClipboard>
  );
};
