import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  TextField,
} from "@mui/material";
import {
  setCode,
  setConfirmPassword,
  setDialogueTab,
  setEmail,
  setPassword,
} from "../../store/auth/authSlice";
import { resetPassword } from "../../store/auth/authSaga";
import * as EmailValidator from "email-validator";

export const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);

  const isFormValid = (): boolean => {
    const validEmail = EmailValidator.validate(authState.email);
    const validCode = authState.code.length >= 6;
    // valid password must have at least 8 characters
    const validPassword = authState.password.length >= 8;
    const passwordsMatch = authState.password === authState.confirmPassword;
    return validEmail && validCode && validPassword && passwordsMatch;
  };

  return (
    <React.Fragment>
      <DialogTitle className="auth_title">HighLowTicker</DialogTitle>
      <DialogContent>
        <DialogContentText className="auth_content">
          <Tab label={"Reset Password"} className="tab_selected" />
        </DialogContentText>
        <p style={{ textAlign: "center" }}>
          A reset code was sent to your email address.
        </p>
        <form autoComplete="on">
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={authState.email}
            autoComplete="on"
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
          />
          <TextField
            margin="dense"
            id="code"
            label="Code"
            type="text"
            fullWidth
            variant="standard"
            value={authState.code}
            autoComplete="off"
            onChange={(e) => {
              dispatch(setCode(e.target.value));
            }}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={authState.password}
            autoComplete="on"
            onChange={(e) => {
              dispatch(setPassword(e.target.value));
            }}
          />
          <TextField
            margin="dense"
            id="confirm_password"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            value={authState.confirmPassword}
            autoComplete="off"
            onChange={(e) => {
              dispatch(setConfirmPassword(e.target.value));
            }}
          />
        </form>
      </DialogContent>
      <DialogActions className="auth_actions">
        <Button onClick={() => dispatch(setDialogueTab("FORGOT_PASSWORD"))}>
          BACK
        </Button>
        <Button
          disabled={!isFormValid() || authState.isLoading}
          onClick={() =>
            dispatch(
              resetPassword({
                email: authState.email,
                code: authState.code,
                newPassword: authState.password,
              })
            )
          }
        >
          RESET PASSWORD
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
