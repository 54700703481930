// Configure Axios Http Object
import axios, { AxiosInstance, AxiosResponse } from "axios";

const baseURL = "http://localhost:4545";

export const healthRoutes = {
  health: "/health",
};

export const authRoutes = {
  login: "/auth",
  forgotPassword: "/auth/resetPassword",
};

export const userRoutes = {
  user: "/user",
};

const createHttp = () => {
  let http: AxiosInstance = axios.create({
    timeout: 10000,
    headers: {},
  });

  http.interceptors.response.use(
    (config: AxiosResponse<any>) => {
      // Any status codes that are within the range of 2xx cause this function to trigger
      return config;
    },
    (error) => {
      throw error.response.data;
    }
  );
  return http;
};

export let http = createHttp();

export default http;
