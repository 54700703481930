import React from "react";
import "./chartPanel.scss";
import { useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import {
  ChartInterval,
  setInterval,
  toggleDrawings,
  toggleHeader,
} from "../../../store/chart/chartSlice";
// @ts-ignore
import TradingViewWidget, { Themes } from "react-tradingview-widget";

export const ChartPanel = () => {
  const dispatch = useDispatch();
  const chartState = useAppSelector((state) => state.chart);

  interface IntervalToolBarButtonProps {
    labelTitle: string;
    labelText: string;
    interval: ChartInterval;
  }

  const IntervalToolBarButton = (props: IntervalToolBarButtonProps) => {
    return (
      <div
        title={props.labelTitle}
        className={
          "item" +
          (chartState.chartInterval === props.interval ? " selected" : "")
        }
        onClick={() => dispatch(setInterval(props.interval))}
      >
        <label>{props.labelText}</label>
      </div>
    );
  };

  return (
    <div className="chart_panel_container">
      <div className="toolbar">
        <div
          title="Toggle drawings"
          className={"item" + (chartState.isDrawingsToggled ? " selected" : "")}
          onClick={() => dispatch(toggleDrawings())}
        >
          <label>🖋</label>
        </div>
        <div
          title="Toggle toolbar"
          className={"item" + (chartState.isHeaderToggled ? " selected" : "")}
          onClick={() => dispatch(toggleHeader())}
        >
          <label>ℹ</label>
        </div>
        <IntervalToolBarButton
          labelTitle={"1 minute interval"}
          labelText={"1M"}
          interval={1}
        />
        <IntervalToolBarButton
          labelTitle={"5 minute interval"}
          labelText={"5M"}
          interval={5}
        />
        <IntervalToolBarButton
          labelTitle={"15 minute interval"}
          labelText={"15M"}
          interval={15}
        />
        <IntervalToolBarButton
          labelTitle={"1 hour interval"}
          labelText={"1H"}
          interval={60}
        />
        <IntervalToolBarButton
          labelTitle={"4 hour interval"}
          labelText={"4H"}
          interval={240}
        />
        <IntervalToolBarButton
          labelTitle={"1 day interval"}
          labelText={"1D"}
          interval={"D"}
        />
      </div>
      <div className="charts">
        <TradingViewWidget
          symbol={"BINANCE:BTCUSDT"}
          theme={Themes.DARK}
          interval={240}
          hide_side_toolbar={!chartState.isDrawingsToggled}
          hide_top_toolbar={!chartState.isHeaderToggled}
          studies={[
            {
              "id": "LinearRegression@tv-basicstudies",
            }
          ]}
          locale="en"
          autosize
        />
        <TradingViewWidget
          symbol={"BINANCE:BTCUSDT"}
          theme={Themes.DARK}
          interval={15}
          hide_side_toolbar={!chartState.isDrawingsToggled}
          hide_top_toolbar={!chartState.isHeaderToggled}
          studies={[
            {
              "id": "LinearRegression@tv-basicstudies",
            }
          ]}
          locale="en"
          autosize
        />
        <TradingViewWidget
          symbol={"BINANCE:BTCUSDT"}
          theme={Themes.DARK}
          interval={5}
          hide_side_toolbar={!chartState.isDrawingsToggled}
          hide_top_toolbar={!chartState.isHeaderToggled}
          studies={[
            {
              "id": "LinearRegression@tv-basicstudies",
            }
          ]}
          locale="en"
          autosize
        />
        <TradingViewWidget
          symbol={"BINANCE:BTCUSDT"}
          theme={Themes.DARK}
          interval={1}
          hide_side_toolbar={!chartState.isDrawingsToggled}
          hide_top_toolbar={!chartState.isHeaderToggled}
          studies={[
            {
              "id": "LinearRegression@tv-basicstudies",
            }
          ]}
          locale="en"
          autosize
        />
      </div>
    </div>
  );
};
