import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import CloseIcon from "@mui/icons-material/Close";
import { DonateState, selectTab } from "../../store/donate/donateSlice";
import btcIcon from "./btc.png";
import ethIcon from "./eth.png";
import usdtIcon from "./usdt.png";
import { CryptoAddress } from "./cryptoAddress";
import "./donateDialogue.scss";
import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

export const DonateDialogue = () => {
  const dispatch = useAppDispatch();
  const donateState: DonateState = useAppSelector(
    (state: RootState) => state.donate
  );

  const addresses = {
    BTC: "bc1qp96fl9ssewzt282v2azu9tcclumkn45vc7nzse",
    ETH: "0xbf6ad1d3c12e441fb023f0f342ce3b0711C0Ec6B",
    USDT: "0xbf6ad1d3c12e441fb023f0f342ce3b0711C0Ec6B",
  };

  if (donateState.selectedTab !== "CLOSED") {
    return (
      <React.Fragment>
        <Dialog
          className="donate_panel"
          fullWidth={true}
          maxWidth="md"
          open={true}
          onClose={() => dispatch(selectTab("CLOSED"))}
        >
          <AppBar className="appbar" sx={{ position: "relative" }}>
            <Toolbar variant="dense">
              <IconButton
                color="secondary"
                edge="start"
                onClick={() => dispatch(selectTab("CLOSED"))}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                color="secondary"
              >
                Donate
              </Typography>
            </Toolbar>
          </AppBar>
          <Typography
            className="donate_title"
            sx={{ ml: 2, flex: 1 }}
            variant="h5"
            color="secondary"
            align="center"
          >
            Your donations are greatly appreciated!
          </Typography>
          <Divider />
          <Typography
            className="donate_paragraph"
            sx={{ ml: 2, flex: 1 }}
            color="secondary"
            paragraph={true}
            align="center"
          >
            As you may know, running the servers behind this application are
            very expensive. Any sort of donation goes a long way to helping me
            afford to keep it up and running and free! 🙂 Click on any of the
            methods below to copy/paste the wallet address. Thank you so much!
          </Typography>
          <Box
            className="donate_methods"
            sx={{ width: "100%", typography: "body1" }}
          >
            <div className="donate_method">
              <CryptoAddress
                icon={btcIcon}
                alt={"btc_icon"}
                type="BTC"
                address={addresses.BTC}
              />
            </div>
            <div className="donate_method">
              <CryptoAddress
                icon={ethIcon}
                alt={"eth_icon"}
                type="ETH"
                address={addresses.ETH}
              />
            </div>
            <div className="donate_method">
              <CryptoAddress
                icon={usdtIcon}
                alt={"usdt_icon"}
                type="USDT"
                address={addresses.USDT}
              />
            </div>
          </Box>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return null;
  }
};
