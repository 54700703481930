import React from "react";
import { Counter } from "./counter";
import { PercentChange } from "./percentChange";
import { Volume } from "./volume";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../store/sidebar/sidebarSlice";
import "./sidebar.scss";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector((state) => state.sidebar.isOpen);
  return (
    <div className="sidebar_container">
      {isOpen ? (
        <div className="sidebar_contents">
          <div className="widgets_container">
            <Volume />
            <PercentChange side={"High"} />
            <PercentChange side={"Low"} />
            <Counter side={"High"} />
            <Counter side={"Low"} />
          </div>
          <div className="bottom_panel" />
        </div>
      ) : null}
      <div className="sidebar_toggle">
        <div className="button" onClick={() => dispatch(toggleSidebar())}>
          {isOpen ? "<" : ">"}
        </div>
      </div>
    </div>
  );
};
