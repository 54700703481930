import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { v4 as uuid4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeSnackbar } from "../../store/snackbar/snackbarSlice";

export const BottomSnackbar = () => {
  const dispatch = useAppDispatch();
  const snackbarState = useAppSelector((state) => state.snackbar);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackbarState.isOpen}
      autoHideDuration={6000}
      onClose={() => dispatch(closeSnackbar())}
      key={uuid4()}
    >
      {snackbarState.alert != null ? (
        <Alert
          key={uuid4()}
          sx={{ width: "100%" }}
          severity={snackbarState.alert.severity}
          onClose={() => dispatch(closeSnackbar())}
        >
          {snackbarState.alert.message}
        </Alert>
      ) : (
        <div />
      )}
    </Snackbar>
  );
};
