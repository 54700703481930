import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./components/App";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e8edfa",
    },
    secondary: {
      main: "#e8edfa",
    },
    divider: "#232632",
    background: {
      default: "#232632",
      paper: "#232632",
    },
    text: {
      primary: "#e8edfa",
      secondary: "#e8edfa",
    },
  },
});

let persistedStore = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
