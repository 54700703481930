import React from "react";
import { useAppSelector } from "../../store/hooks";
import { ChartPanel } from "./chart/chartPanel";
import { HomePanel } from "./home/homePanel";
import "./panel.scss";

export const Panel = () => {
  const selectedTab = useAppSelector((state) => state.panel.selectedTab);

  const getSelectedPanel = () => {
    switch (selectedTab) {
      case "HOME":
        return <HomePanel />;
      case "CHARTS":
        return <ChartPanel />;
    }
  };

  return (
    <div className="panel_container">
      <div className="selected_panel">{getSelectedPanel()}</div>
    </div>
  );
};
