import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import saga from "./sagas";
import tickerReducer from "./ticker/slice";
import panelReducer from "./panel/panelSlice";
import chartReducer from "./chart/chartSlice";
import sidebarReducer from "./sidebar/sidebarSlice";
import authReducer from "./auth/authSlice";
import snackbarReducer from "./snackbar/snackbarSlice";
import userReducer from "./user/userSlice";
import donateReducer from "./donate/donateSlice";

const reducers = combineReducers({
  ticker: tickerReducer,
  panel: panelReducer,
  chart: chartReducer,
  sidebar: sidebarReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  donate: donateReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["ticker", "auth", "snackbar", "donate"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
