import { AuthToken } from "../../models/auth/AuthToken";
import { AxiosResponse } from "axios";
import { LoginDetails } from "../../models/auth/LoginDetails";
import http, { authRoutes, userRoutes } from "../http";
import { ResetPasswordDetails } from "../../models/auth/ResetPasswordDetails";
import { RegisterDetails } from "../../models/auth/RegisterDetails";
import { User } from "../../models/User";

export const loginUser = async (details: LoginDetails): Promise<AuthToken> => {
  try {
    const response: AxiosResponse<AuthToken> = await http.post(
      authRoutes.login,
      {
        email: details.email,
        password: details.password,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerUser = async (details: RegisterDetails): Promise<User> => {
  try {
    const response: AxiosResponse<User> = await http.post(
      userRoutes.user,
      details
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await http.post(
      authRoutes.forgotPassword,
      {
        email: email,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (
  details: ResetPasswordDetails
): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await http.put(
      authRoutes.forgotPassword,
      details
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
