import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthToken } from "../../models/auth/AuthToken";

export interface AuthState {
  dialogueTab: DialogueTab;
  isLoading: boolean;
  isCaptchaRequired: boolean;
  code: string;
  email: string;
  password: string;
  confirmPassword: string;
  authToken: AuthToken | null;
}

type DialogueTab =
  | "LOG_IN"
  | "REGISTER"
  | "FORGOT_PASSWORD"
  | "RESET_PASSWORD"
  | "CLOSED";

const initialState: AuthState = {
  dialogueTab: "CLOSED",
  isLoading: false,
  isCaptchaRequired: false,
  code: "",
  email: "",
  password: "",
  confirmPassword: "",
  authToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setDialogueTab: (state, action: PayloadAction<DialogueTab>) => {
      if (action.payload === "CLOSED") {
        state.email = "";
        state.password = "";
        state.confirmPassword = "";
        state.code = "";
      }
      state.dialogueTab = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setConfirmPassword: (state, action: PayloadAction<string>) => {
      state.confirmPassword = action.payload;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setCaptcha: (state, action: PayloadAction<boolean>) => {
      state.isCaptchaRequired = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<AuthToken | null>) => {
      state.authToken = action.payload;
    },
  },
});

export const {
  setDialogueTab,
  setLoading,
  setEmail,
  setPassword,
  setConfirmPassword,
  setCode,
  setCaptcha,
  setAuthToken,
} = authSlice.actions;

export default authSlice.reducer;
