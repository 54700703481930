import React from "react";
import "./tickerStatus.scss";

interface TickerStatusProps {
  connected: boolean;
}

export const TickerStatus = (props: TickerStatusProps) => {
  return (
    <div className="ticker_status_container">
      <div className={"circle" + (props.connected ? " connected" : "")} />
      <span>{props.connected ? "Connected" : "Reconnecting..."}</span>
    </div>
  );
};
