import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCaptcha,
  setDialogueTab,
  setEmail,
  setPassword,
} from "../../store/auth/authSlice";
import { loginUser } from "../../store/auth/authSaga";
import { AuthCaptcha } from "./authCaptcha";
import * as EmailValidator from "email-validator";

export const Login = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);

  const isFormValid = (): boolean => {
    const validEmail = EmailValidator.validate(authState.email);
    const validPassword = authState.password.length >= 8;
    return validEmail && validPassword;
  };

  const setCaptchaDone = (open: boolean) => {
    dispatch(setCaptcha(open));
  };

  return (
    <React.Fragment>
      <DialogTitle className="auth_title">HighLowTicker</DialogTitle>
      <DialogContent>
        <DialogContentText className="auth_content">
          <Tab label={"Log In"} className="tab_selected" />
          <Tab
            label={"Register"}
            onClick={() => dispatch(setDialogueTab("REGISTER"))}
          />
        </DialogContentText>
        <form autoComplete="off">
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={authState.email}
            autoComplete="on"
            onChange={(e) => {
              dispatch(setEmail(e.target.value));
            }}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={authState.password}
            autoComplete="on"
            onChange={(e) => {
              dispatch(setPassword(e.target.value));
            }}
          />
        </form>
        <AuthCaptcha
          open={authState.isCaptchaRequired}
          setCaptcha={setCaptchaDone}
        />
      </DialogContent>
      <DialogActions className="auth_actions">
        <Button onClick={() => dispatch(setDialogueTab("FORGOT_PASSWORD"))}>
          FORGOT PASSWORD
        </Button>
        <Button
          disabled={!isFormValid() || authState.isLoading}
          onClick={() =>
            dispatch(
              loginUser({
                email: authState.email,
                password: authState.password,
              })
            )
          }
        >
          LOG IN
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
