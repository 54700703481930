import { all } from "redux-saga/effects";
import {
  watchForgotPassword,
  watchLoginUser,
  watchLogoutUser,
  watchRegisterUser,
  watchResetPassword,
} from "./auth/authSaga";
import { watchGetUser } from "./user/userSaga";

export default function* rootSaga() {
  yield all([
    watchLoginUser(),
    watchRegisterUser(),
    watchForgotPassword(),
    watchResetPassword(),
    watchGetUser(),
    watchLogoutUser(),
  ]);
}
