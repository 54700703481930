import React from "react";
import { PriceRecord } from "../../models/PriceRecord";
import { useAppSelector } from "../../store/hooks";
import "./tickerSide.scss";

export const TickerHighLow = () => {
  const tickerState = useAppSelector((state) => state.ticker);

  return (
    <div className="ticker_sides">
      <TickerSide prices={tickerState.tickerRecords.low} />
      <TickerSide prices={tickerState.tickerRecords.high} />
    </div>
  );
};

interface TickerProps {
  prices: PriceRecord[];
}

export const TickerSide = (props: TickerProps) => {
  return (
    <div className="ticker_side_container">
      <div className="ticker_row header">
        <div className="ticker_row_cell">
          <b>Symbol</b>
        </div>
        <div className="ticker_row_cell">
          <b>Price</b>
        </div>
        <div className="ticker_row_cell">
          <b>Count</b>
        </div>
      </div>
      {props.prices.map((p, i) => (
        <div
          key={i}
          className={
            "ticker_row" +
            ((p.isHigh ? " day_high" : " day_low") + (p.isYear ? " year" : ""))
          }
        >
          <div className="ticker_row_cell">{p.symbol}</div>
          <div className="ticker_row_cell">{p.price}</div>
          <div className="ticker_row_cell">{p.count}</div>
        </div>
      ))}
    </div>
  );
};
