import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DonateState {
  selectedTab: PanelTab;
}

export type PanelTab = "CLOSED" | "DONATE";

const initialState: DonateState = {
  selectedTab: "CLOSED",
};

export const donateSlice = createSlice({
  name: "donate",
  initialState,
  reducers: {
    selectTab: (state, action: PayloadAction<PanelTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { selectTab } = donateSlice.actions;

export default donateSlice.reducer;
