import React from "react";
import "./tickerIntervalSelect.scss";
import { convertToUIText, HighLowInterval } from "../../models/HighLowInterval";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toggleInterval } from "../../store/ticker/slice";

export const TickerIntervalSelect = () => {
  const dispatch = useAppDispatch();
  const intervalState = useAppSelector((state) => state.ticker.highLowInterval);
  return (
    <div className="ticker_interval_container">
      {Object.keys(HighLowInterval)
        .map((obj, i) => {
          const interval: HighLowInterval = obj as HighLowInterval;
          const isSelected: boolean = intervalState === interval;
          return (
            <div
              key={i}
              className={"panel_tab" + (isSelected ? " selected" : "")}
              onClick={() => dispatch(toggleInterval(interval))}
            >
              {convertToUIText(interval)}
            </div>
          );
        })
        .reverse()}
    </div>
  );
};
